import Vue from "vue";
import App from "./App.vue";
import "./App.less";
import "animate.css";
import axios from "axios";
import router from "./router";
import store from "./store";
import Cookies from "js-cookie";
// import "@/assets/css/common.css";
import AvueUeditor from "avue-plugin-ueditor";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import Avue from "@smallwei/avue";
import "@smallwei/avue/lib/index.css";
Vue.use(Avue);

Vue.use(AvueUeditor);

Vue.use(ElementUI);
Vue.config.productionTip = false;

// axios.defaults.baseURL = "https://vuapi.vu818.com"; //线上
axios.defaults.baseURL = "https://g.api.okvu.cn"; //线上
// axios.defaults.baseURL = "http://vytxx.cn"; //线下

axios.interceptors.request.use(config => {
    const token = Cookies.get("token");
    if (token) {
        config.headers["token"] = token;
    }
    return config;
});

Vue.prototype.$bus = new Vue();
// 定义全局变量
Vue.mixin({
    data() {
        return {
            // 拼接上传图片域名的全局变量
            // imgBaseUrl: axios.defaults.baseURL,
            // imgBaseUrl: "https://vuapi.vu818.com",
            imgBaseUrl: axios.defaults.baseURL,
            bfc: function (done) {
                this.$confirm("是否确认关闭?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => done())
                    .catch(() => false);
            },
        };
    },
    methods: {},
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app");
