<template>
    <!-- 侧边栏 -->
    <div class="aside">
        <div class="aside_box">
            <!-- 一级 -->
            <ul class="aside_ul">
                <template v-for="(item, index) in asideData">
                    <li
                        v-show="!(item.value == 'vyHomePage')"
                        class="aside_li"
                        :class="{ aaa: a[0] == item.label }"
                        v-if="item.isShow"
                        :key="index"
                        @click="gotoHomePage(item.value, item.children)"
                        ref="one"
                    >
                        <div class="aside_item" @click="clickAnimation1(item.children, item.value + index)">
                            <div class="item_title" style="flex: 1">
                                <img src="https://iv.okvu.cn/vugw/img/tjyj.png" class="tjyj" v-if="item.label == '推荐有奖'" />
                                <span v-else>{{ item.label }}</span>
                            </div>
                            <VyIcon :name="item.label" scale="1.3"></VyIcon>
                            <VyIcon
                                name="arrowRight"
                                class="arrow_right_img"
                                :class="item.value + index == activeIndex1 && item.children ? 'arrow_botton' : ''"
                            ></VyIcon>
                            <!-- <div class="item_img empty_picture_img"></div> -->
                            <!-- <div
                                class="item_link arrow_right_img"
                                :class="item.value + index == activeIndex1 ? 'arrow_botton' : ''"
                            ></div> -->
                        </div>
                        <!-- 二级 -->
                        <ul
                            class="ul"
                            :style="
                                item.value + index == activeIndex1
                                    ? {
                                          height: `${
                                              (item.children ? item.children.filter(e => e.isShow).length : 0) * 39 + item.h
                                          }px`,
                                      }
                                    : ''
                            "
                        >
                            <template v-for="(el, ii) in item.children">
                                <li v-if="el.isShow" :key="ii" @click="goTo(el.route, el.children)" ref="two">
                                    <div
                                        class="children_item"
                                        :class="{ bbb: a[1] == item.label + el.label && !a[2] }"
                                        @click="clickAnimation2(el.children, el.route + ii, item)"
                                    >
                                        <VyIcon
                                            name="arrowRight"
                                            class="arrow_right_img"
                                            :class="el.route + ii == activeIndex2 ? 'arrow_botton' : ''"
                                        ></VyIcon>
                                        <!-- <div
                                            class="item_link arrow_right_img"
                                            :class="el.route + ii == activeIndex2 ? 'arrow_botton' : ''"
                                        ></div> -->
                                        <div class="item_title">
                                            <span>{{ el.label }}</span>
                                        </div>
                                    </div>
                                    <!-- 三级 -->
                                    <ul
                                        class="ul"
                                        :style="
                                            el.route + ii == activeIndex2
                                                ? { height: `${(el.children ? el.children.length : 0) * 39}px` }
                                                : ''
                                        "
                                    >
                                        <li v-for="(e, i) in el.children" :key="i" @click="goTo(e.route, e.children)" ref="three">
                                            <div
                                                class="children_detail_item"
                                                :class="{ bbb: a[2] == item.label + el.label + e.label }"
                                            >
                                                <div class="item_title">
                                                    {{ e.label }}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>
<script>
import VyIcon from "@/components/customs/VyIcon.vue";
import asideData from "./asidData";

export default {
    components: { VyIcon },
    data() {
        return {
            asideData: [],
            activeIndex1: null,
            activeIndex2: null,
            a: [],
        };
    },
    created() {
        let d = this.$cl(asideData);
        d.forEach(e => {
            e.h = 0;
            if (e.children) {
                e.children.forEach(el => {
                    el.h = 0;
                });
            }
        });
        this.asideData = d;
    },
    methods: {
        // 前往首页
        gotoHomePage(route, children) {
            // 有子级的情况下就不允许点击父级跳转
            if (children && children != []) return;
            this.goTo(route);
        },
        goTo(route, children) {
            // 有子级的情况下就不允许点击父级跳转
            if (children && children != []) return;
            this.$bus.$emit("chooseHomePage", 0);

            this.$store.commit("chooseModule/onChoose", "index");

            // 获取当前路由对象
            const currentRoute = this.$router.currentRoute;

            // 构建要重定向的目标路由对象
            const redirectRoute = "/home/" + route;

            // 如果当前路由与目标路由相同，则不执行重定向
            if (currentRoute.path === redirectRoute) {
                return;
            }

            // 执行重定向
            this.$router.push(redirectRoute);
        },

        // 动画
        clickAnimation1(children, index) {
            if (this.activeIndex1 == index) return (this.activeIndex1 = null);
            if (!children) return;

            this.activeIndex1 = index;
        },
        clickAnimation2(children, index, item) {
            if (this.activeIndex2 == index) {
                item.h = 0;
                this.activeIndex2 = null;
                return;
            } else {
                item.h = children ? children.length * 39 : "";
            }
            if (!children) return;
            this.activeIndex2 = index;
        },
    },
    watch: {
        "$route.params.name": {
            handler(newVal) {
                let arr = [];
                let arrTwo = [];
                setTimeout(() => {
                    this.asideData.forEach((e, i) => {
                        if (e.value == newVal) {
                            arr = [e.label];
                            arrTwo = [e.value + i];
                        }
                        if (e.children) {
                            e.children.forEach((el, ind) => {
                                if (el.route == newVal) {
                                    arr = [e.label, e.label + el.label];
                                    arrTwo = [e.value + i, el.route + ind];
                                }
                                if (el.children) {
                                    el.children.forEach((ele, index) => {
                                        if (ele.route == newVal) {
                                            arr = [e.label, e.label + el.label, e.label + el.label + ele.label];
                                            arrTwo = [e.value + i, el.route + ind, ele.route + index];
                                        }
                                    });
                                }
                            });
                        }
                    });
                    this.a = arr;
                    this.activeIndex1 = arrTwo[0];
                    this.activeIndex2 = arrTwo[2] ? arrTwo[1] : null;
                }, 100);
            },
            immediate: true,
        },
    },
};
</script>
<style scoped lang="less">
.act {
    color: #b166ff !important;
}
.aaa {
    background-color: #efeefd;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    & > .aside_item {
        background: linear-gradient(to right, #827af3, #b47af3);
        color: white;
        &:hover {
            color: white !important;
        }
    }
}
.bbb {
    background-color: #e0defc;
    color: #827af3;
}
.aside_box {
    height: calc(100vh - 60px);
    position: fixed;
    overflow-y: scroll;
    width: 218px;
    li {
        list-style: none;
    }

    .aside_ul {
        height: 100%;

        .aside_li {
            min-height: 36px;
            font-size: 14px;
            // 一级
            .aside_item {
                padding: 15px;
                display: flex;
                align-items: center;
                // border-bottom: 1px solid #e5e5e5;
                transition: 0.4s;
                .item_img {
                    margin: 2px 3px 0 0;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                    color: #b166ff;
                    cursor: pointer;
                }
            }
            // 二级
            .children_item {
                padding: 10px 40px;
                display: flex;
                align-items: center;
                transition: 0.4s;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                    color: #b166ff;
                    cursor: pointer;
                }

                .item_link {
                    margin-right: 10px;
                }
            }
            // 三级
            .children_detail_item {
                height: 39px;
                line-height: 39px;
                padding: 0px 80px;

                &:hover {
                    color: #b166ff;
                    cursor: pointer;
                }
            }

            .ul {
                transition: 0.5s;
                height: 0px;
                overflow: hidden;
            }
        }
    }
}

.aside_box::-webkit-scrollbar {
    width: 0; /* 隐藏滚动条 */
}

.aside_box::-webkit-scrollbar-thumb {
    background-color: transparent; /* 隐藏滚动条thumb（滑块） */
}

.arrow_right_img {
    // width: 16px;
    // height: 16px;
    // background: url("../../../assets/common-imgs/asideAssets/li_sprites.png") -10px -10px;
    transition: 0.5s;
    margin-left: 2px;
}

// 图标旋转动画
.arrow_botton {
    transform: rotate(90deg) !important;
}

.empty_picture_img {
    width: 16px;
    height: 16px;
    background: url("../../../assets/common-imgs/asideAssets/li_sprites.png") -46px -10px;
}
.tjyj {
    // width: 60%;
    height: 15px;
    object-fit: contain;
}
</style>
