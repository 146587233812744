<template>
    <div style="border-radius: 20px; overflow: hidden">
        <div :id="toolbarId" ref="toolbar"><!-- 工具栏 --></div>
        <div :id="editorId" :style="`height: ${height}px`"><!-- 编辑器 --></div>
    </div>
</template>

<script>
const { createEditor, createToolbar } = window.wangEditor;

export default {
    props: {
        getTxt: {
            // 输入内容change回调
            type: Function,
            required: true,
        },
        txt: {
            // 初始文本
            type: String,
            default: "",
        },
        height: {
            // 编辑区域高度
            type: Number,
            default: 300,
        },
    },
    data() {
        return {
            toolbarId: "toolbar" + parseInt(Math.random() * 100000),
            editorId: "editor" + parseInt(Math.random() * 100000),
        };
    },
    mounted() {
        let _this = this;
        const editorConfig = {
            placeholder: "",
            onChange(editor) {
                const html = editor.getHtml();
                _this.getTxt(html);
            },
            MENU_CONF: {
                uploadImage: {
                    server: _this.imgBaseUrl + "/api/Project/up_ueditorData",
                    headers: {
                        token: _this.token,
                    },
                },
            },
        };
        const editor = createEditor({
            selector: "#" + _this.editorId,
            html: _this.txt,
            config: editorConfig,
            mode: "default", // or 'simple'
        });
        const toolbarConfig = {};
        const toolbar = createToolbar({
            editor,
            selector: "#" + _this.toolbarId,
            config: toolbarConfig,
            mode: "default", // or 'simple'
        });
        // 添加一个不具有响应式的数据
        _this.editor = editor;
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    watch: {
        txt(newVal) {
            if (newVal) {
                this.editor.setHtml(newVal);
            } else {
                this.editor.clear();
            }
        },
    },
};
</script>

<style lang="less" scoped>
// 提高全屏时的层级
.w-e-full-screen-container {
    z-index: 999;
}
</style>
